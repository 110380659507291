import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layouts'
class App extends Component {
  render() {
    return (
      <Layout>
        <div className="landing-page">
          <GatsbyImage
            image={
              this.props.data.allContentfulAsset.edges[0].node.gatsbyImageData
            }
            alt="Landing Page Img"
            className="landing-page__img fadeInUp"
          />
        </div>
      </Layout>
    )
  }
}

export default App

export const query = graphql`
  query indexQuery {
    allContentfulAsset(filter: { title: { eq: "Opening" } }) {
      edges {
        node {
          gatsbyImageData(width: 2000, placeholder: NONE)
          fluid(maxWidth: 2000) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
`
